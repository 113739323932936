/**
 *
 * Add the app configurations as constants here and export them to be used on
 * the app module.
 *
 */
export const PASSWORD_PATTERN = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,100}$';
export const NAME_PATTERN = '^[A-Za-z]+[ \t]?[A-Za-z- ]+?[ \t]*$';
export const POSTAL_PATTERN = /^\d{5}(-\d{4})?$/;
export const SWEEPSTAKES_JAN = '202101-0001';
export const NUMBERS_ONLY_PATTERN = '^[0-9]*$';
export const ALPHANUMERIC_PATTERN = '^[a-zA-Z0-9_ ]*$';
export const EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,5}$';
export const ATT = 'att';
export const TMO = 'tmo';
export const SIM = 'sim';
export const ESIM = 'esim';
export const PHYSICAL = 'physical';
export const TMO_SKU_NUMBER = 'SIMQTLTMO4GLTE';
export const ESIM_TMO_SKU_NUMBER = 'ESIMQTLTMO4GLTE';
export const ESIM_ATT_SKU_NUMBER = 'ESIMQTL4GLTE';
export const ATT_SKU_NUMBER = 'SIMQTL4GLTE';
export const SKIP_DEVICE_ATT = { network: 'att', networkType: 'LTE', skuIdentifier: 'G', skuNumber: ATT_SKU_NUMBER, verified: true };
export const SKIP_DEVICE_ATT_ESIM = { network: 'att', networkType: 'LTE', skuIdentifier: 'GE', skuNumber: ESIM_ATT_SKU_NUMBER, verified: true }; // future?
export const SKIP_DEVICE_TMO = { network: 'tmo', networkType: 'LTE', skuIdentifier: 'T', skuNumber: TMO_SKU_NUMBER, verified: true }
export const SKIP_DEVICE_TMO_ESIM = { network: 'tmo', networkType: 'LTE', skuIdentifier: 'TE', skuNumber: ESIM_TMO_SKU_NUMBER, verified: true };
export const DEVICE_ATT = { network: 'att', networkType: 'LTE', skuIdentifier: 'G', skuNumber: ATT_SKU_NUMBER };
export const DEVICE_ATT_ESIM = { network: 'att', networkType: 'LTE', skuIdentifier: 'GE', skuNumber: ESIM_ATT_SKU_NUMBER }; 
export const DEVICE_TMO = { network: 'tmo', networkType: 'LTE', skuIdentifier: 'T', skuNumber: TMO_SKU_NUMBER }
export const DEVICE_TMO_ESIM = { network: 'tmo', networkType: 'LTE', skuIdentifier: 'TE', skuNumber: ESIM_TMO_SKU_NUMBER };
export const BRAND_NAME = 'Qué tal Mobile';
export const CAPTCHA_BOT_ERROR_CODE = 466;