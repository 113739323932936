import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot, RoutesRecognized } from '@angular/router';
import { AppHelperService, IPageMeta } from '@ztarmobile/zwp-service';
import { AuthHttp } from '@ztarmobile/zwp-services-auth';
import { ActionsAnalyticsService, FirebaseUserProfileService, IMarketingDetails } from '@ztarmobile/zwp-service-backend';
import { filter, takeWhile } from 'rxjs/operators';
import { ModalHelperService } from '../services/modal-helper.service';
import { ACTIVATION_ROUTE_URLS, ROUTE_URLS } from './app.routes.names';
import { AppState } from './app.service';
import { ContentfulService } from 'src/services/contentful.service';
import { Meta } from '@angular/platform-browser';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CheckoutService } from './shop/checkout/checkout.service';
import { TranslateService } from '@ngx-translate/core';
import { BRAND_NAME } from './app.config';
import { getAuth } from 'firebase/auth';
import { environment } from '@env/environment';
import { jwtDecode } from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Qué tal Mobile';
  public maintenanceExists = false;
  public alertBannerExists = false;
  public removeMinHeight = true;
  public isMarketingCampaign = false;
  public alertBannerHeight;
  public pageUrl;
  public enableIap = true;
  public isAlerPopupDisplayed = false;
  public INACTIVITY_WARNING_TIME = 25 * 60;

  private isBackButton: boolean;
  private alive = true;
  private idleInterval: any;
  private timeLeft;
  private lastActivityTime;
  
  constructor(
    public appState: AppState, private location: Location, private appHelper: AppHelperService,
    private actionsAnalyticsService: ActionsAnalyticsService, private router: Router, private modalHelper: ModalHelperService,
    private userProfileService: FirebaseUserProfileService, private authHttp: AuthHttp, private meta: Meta,
    private contentfulService: ContentfulService, private changeDetector: ChangeDetectorRef,
    private angularAuthService: AngularFireAuth, private checkoutService: CheckoutService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private dialogRef: MatDialog) {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('es');
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!!params && params.lang) {
        this.appState.useLanguage(params.lang);
        sessionStorage.setItem('lang', params.lang);
        this.meta.updateTag({ property: 'og:description', content: params.lang === 'en' ? 'Wireless that Speaks Your Language' : 'Telefonía Móvil que habla tu idioma' });
      }
    });
  }

  ngOnInit(): void {
    if(!localStorage.getItem('googleToken') && !environment.production && !!this.enableIap) {
      this.modalHelper.showGoogleLoginsModal();
    } else if (!!localStorage.getItem('googleToken') && !!this.checkGoogleTokenExpirey(localStorage.getItem('googleToken')) && !environment.production && !!this.enableIap) {
      localStorage.removeItem('googleToken');
        // call modal again to login
        this.modalHelper.showGoogleLoginsModal();
    }
    this.subscribeRouterEvents();
    this.authHttp.setLanguage('es');
    this.isIE();
    this.isSamsungBrowser();
    this.callAnotherRequiredChecks();
    this.userProfileService.userProfileObservable.pipe(takeWhile(() => this.alive), filter((user) => !!user)).subscribe((user) => {
      if (!!user && user.preferredLanguage) {
        this.appState.useLanguage(user.preferredLanguage);
        sessionStorage.setItem('lang', user.preferredLanguage);
        this.meta.updateTag({ property: 'og:description', content: user.preferredLanguage === 'en' ? 'Wireless that Speaks Your Language' : 'Telefonía Móvil que habla tu idioma' });
      
      }
    });
  }

  ngAfterViewInit(): void {
    if (!!this.router.events) {
      this.router.events.pipe(filter((event) => event instanceof RoutesRecognized)
        , takeWhile(() => this.alive))
        .subscribe((event: RoutesRecognized) => {
          const route = this.getLastChildOnTreeOfActivatedRoute(event.state);
          this.checkRouterDataChanges(route);
          this.checkCampaigns(route);
          this.checkUtms(route);
          this.removeMinHeight = event.state.url.includes(ACTIVATION_ROUTE_URLS.BASE);
        });
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.idleInterval) {
      clearInterval(this.idleInterval);
    }
    window.removeEventListener('mousemove', this.onUserActivity.bind(this));
    window.removeEventListener('keypress', this.onUserActivity.bind(this));
    window.removeEventListener('scroll', this.onUserActivity.bind(this));
  }
  
  public checkGoogleTokenExpirey(token): boolean {
    const decodedToken: any = jwtDecode(token);
    const expirationTime = decodedToken.exp;
     // Current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);
     // return true or false if token has expired
    return expirationTime < currentTime ;
  }
  public isIE(): boolean {
    const match = navigator.userAgent.search(/(?:MSIE|Trident\/.*; rv:)/);
    let isIE = false;
    if (match !== -1) {
      isIE = true;
    }
    return isIE;
  }

  public isSamsungBrowser(): boolean {
    const isSamsungBrowser = (navigator.userAgent.includes('SamsungBrowser') ||
      navigator.userAgent.includes('Samsung Browser')) && parseFloat(navigator.appVersion.substr(0, 3)) <= 4.0;
    return isSamsungBrowser;
  }

  private checkRouterDataChanges(route: ActivatedRouteSnapshot): void {
    const data: IPageMeta = route.data as IPageMeta;
    this.appHelper.setPageTitle(!!data && !!data.title ? `${data.title}` : `${BRAND_NAME}`);
    if (!!data.description) {
      this.appHelper.setPageDescription(data.description);
    }
  }

  // workaround since ActivatedRoute is not working.
  private getLastChildOnTreeOfActivatedRoute(snapshot: RouterStateSnapshot): ActivatedRouteSnapshot {
    let parent = snapshot.root;
    while (!!parent.firstChild) {
      parent = parent.firstChild;
    }
    return parent;
  }

  private handleRouterEventChange(event: NavigationEnd): void {
    if (event instanceof NavigationEnd) {
      this.pageUrl = event.url;
      this.appState.globalAlertHeight.subscribe((value) => {
        this.alertBannerHeight = value;
        this.changeDetector.detectChanges();
      });
      this.meta.updateTag({ property: 'og:url', content: window.location.href });
      this.meta.updateTag({ name: 'twitter:url', content: window.location.href });
      this.actionsAnalyticsService.trackPageView(event);
      this.location.subscribe((e) => {
        // this is fired when user click back or forward of browser
        if (e.pop) {
          this.isBackButton = true;
          setTimeout(() => this.isBackButton = false, 500);
        }
      });
      setTimeout(() => {
        if (!this.isBackButton && !this.router.parseUrl(this.router.url).toString().includes('#promoPlans')) {
          document.body.scrollTop = 0;
          window.scroll(0, 0);
        }
      }, 200);
    }
  }

  private subscribeRouterEvents(): void {
    if (!!this.router.events) {
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)
        , takeWhile(() => this.alive))
        .subscribe((event: NavigationEnd) => {
          this.handleRouterEventChange(event);
        });
    }
  }

  private checkUtms(route: ActivatedRouteSnapshot): void {
    const queryParams = route.queryParams;
    let utms = JSON.parse(sessionStorage.getItem('utms')); // read already saved utms
    if (!!queryParams && !!queryParams[ROUTE_URLS.PARAMS.UTM_CAMPAIGN]
      && !!queryParams[ROUTE_URLS.PARAMS.UTM_MEDIUM]
      && !!queryParams[ROUTE_URLS.PARAMS.UTM_SOURCE]) {
      this.appState.utmsCampaignParamsReplySubject.next(queryParams);
      this.appState.utmsCampaignReplySubject.next(true);
      this.isMarketingCampaign = true;
      const details: IMarketingDetails = Object.assign({} as IMarketingDetails, queryParams);
      details.url = window.location.href.split('?')[0];
      sessionStorage.setItem('utms', JSON.stringify(details)); // this is to always save the latest utm values
      utms = JSON.parse(sessionStorage.getItem('utms')); // update the saved utms
    }
    if (!!utms) {
      this.appState.utmsCampaignReplySubject.next(true);
      this.isMarketingCampaign = true;
      utms = JSON.parse(sessionStorage.getItem('utms')); // update the saved utms
    }
  }

  private checkCampaigns(route: ActivatedRouteSnapshot): void {
    const queryParams = route.queryParams;
    const status = sessionStorage.getItem('termsAccepted');
    let utms = JSON.parse(sessionStorage.getItem('utms')); // read already saved utms
    if (!!queryParams && !!queryParams[ROUTE_URLS.PARAMS.UTM_CAMPAIGN] && queryParams[ROUTE_URLS.PARAMS.UTM_CAMPAIGN] === 'fsi-demo'
      && !!queryParams[ROUTE_URLS.PARAMS.UTM_MEDIUM] && queryParams[ROUTE_URLS.PARAMS.UTM_MEDIUM] === 'affiliate'
      && !!queryParams[ROUTE_URLS.PARAMS.UTM_SOURCE] && queryParams[ROUTE_URLS.PARAMS.UTM_SOURCE] === 'fsi') {
      this.appState.campaignQueryParamsReplySubject.next(queryParams);
      this.appState.isMarktingCampaignReplySubject.next(true);
      this.isMarketingCampaign = true;
      sessionStorage.setItem('utms', JSON.stringify(queryParams)); // this is to always save the latest utm values
      utms = JSON.parse(sessionStorage.getItem('utms')); // update the saved utms
    }
    if (!!utms && utms.utm_campaign === 'fsi-demo') {
      this.appState.isMarktingCampaignReplySubject.next(true);
      this.isMarketingCampaign = true;
      utms = JSON.parse(sessionStorage.getItem('utms')); // update the saved utms
    }
  }
  private checkIfMaintenanceExist(): void {
    this.contentfulService.getContent('maintenanceModel').subscribe(contents => {
      if (!!contents) {
        const result = contents[0].fields;
        const maxDate = new Date(result.endDisplayDate);
        const displayDate = new Date(result.maintenanceDisplayDate);
        const todayDate = new Date();
        if (todayDate < maxDate && todayDate >= displayDate) {
          this.maintenanceExists = true;
        }
      }

    });
  }
  private logout(): void {
    this.clearInactivityCheck();
    this.angularAuthService.signOut().then(() => {
      this.appState.userLoggedIn.next(undefined);
      this.dialogRef.closeAll();
      this.appState.clearSessionStorage();
      this.checkoutService.setPayments({ card: { address1: '', address2: '', cardCode: '', cardNumber: '', last4: '', id: '', city: '', state: '', country: '', postalCode: '', method: '', name: '', alias: '', fullName: '', brand: '' } });
      this.router.navigate([ROUTE_URLS.HOME]);
    })
  }
  private onUserActivity(): void {
    // Set userActive to true and reset the last activity time
    this.lastActivityTime = Date.now();  // Update the last activity time

    // Stop the counter (this only stops the countdown, not the timer)
    if (this.timeLeft < this.INACTIVITY_WARNING_TIME) {
      this.timeLeft = this.INACTIVITY_WARNING_TIME; // Reset time left
    }
  }
  // Checks for user activity and starts the inactivity timer
  private checkUserActivity(): void {
    getAuth().onAuthStateChanged(user => {
      if (!!user && !user?.isAnonymous) {
        this.timeLeft = this.INACTIVITY_WARNING_TIME; // Reset timer to 25 minutes
        this.lastActivityTime = Date.now(); // Track the initial time
        this.clearInactivityCheck();
        // Start interval to check inactivity
        this.idleInterval = setInterval(() => {
          const currentTime = Date.now();
          // total time that has passed since the user’s last activity.
          const elapsedTime = Math.floor((currentTime - this.lastActivityTime) / 1000); // In seconds
          // If user has been inactive for more than 25 minutes, show popup
          if (elapsedTime >= this.INACTIVITY_WARNING_TIME && !this.isAlerPopupDisplayed) {
            this.showInactivityPopup();
          }
        }, 1000); // Check every second
      } else {
        // If no user is logged in, clear interval and remove event listeners
        this.clearInactivityCheck();
      }
    });

    // Add event listeners for user activity
    window.addEventListener('mousemove', this.onUserActivity.bind(this));
    window.addEventListener('keypress', this.onUserActivity.bind(this));
    window.addEventListener('scroll', this.onUserActivity.bind(this));
  }

  private clearInactivityCheck(): void {
    // Clear the interval and remove event listeners when user logs out or session ends
    if (this.idleInterval) {
      clearInterval(this.idleInterval);
      this.dialogRef.closeAll();
    }
    window.removeEventListener('mousemove', this.onUserActivity.bind(this));
    window.removeEventListener('keypress', this.onUserActivity.bind(this));
    window.removeEventListener('scroll', this.onUserActivity.bind(this));
  }

  private resetVariables(): void {
    this.timeLeft = this.INACTIVITY_WARNING_TIME;
    this.isAlerPopupDisplayed = false;
    this.checkUserActivity();
  }
  private refreshUserToken(): void {
    this.resetVariables();
    window.location.reload();
  }
   // Shows inactivity warning popup
   private showInactivityPopup(): void {
    this.isAlerPopupDisplayed = true;
    const desc = this.translate.instant('app-component.you-have-been-inactive-for-a-while');
    const desc2 = this.translate.instant('app-component.signed-in');
    const desc3 = this.translate.instant('app-component.or-to');
    const desc4 = this.translate.instant('app-component.logout');
    const desc5 = this.translate.instant('app-component.you-will-be-logged-out');
    const desc6 = this.translate.instant('app-component.automatically');
    const customHTML = `<p class="desc" translate>${desc}<b translate>${desc2}</b><span translate>${desc3}</span><b>${desc4}</b></p>
    <p class="desc last" translate>${desc5}<b translate>${desc6}</b></p>`;
    this.modalHelper.showAlertSecurityModal(this.translate.instant('app-component.your-session-is-about-to-end'), this.translate.instant('app-component.stay-connected'), this.translate.instant('app-component.log-out'), true, 'timer-alert-modal', customHTML).afterClosed().subscribe((res) => {
        this.isAlerPopupDisplayed = false; // Reset the popup flag
        if (res === 'logout') {
          this.logout();
        } else {
          // Refresh token and reset timers
          this.refreshUserToken();
        }
      });
  }
  
  private callAnotherRequiredChecks(): void {
    this.checkIfMaintenanceExist();
    this.checkUserActivity();
    this.appState.validatePromo();
    this.appState.promoTimeRemaining();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: BeforeUnloadEvent) {
      this.logout();
  }
}
